import * as React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import HPPLayout from "../../components/layouts/layout-hpp"
import Seo from "../../components/seohp"

// import VideoWithPlayButton from "../../components/shared/brightcove-scroll-bttn";
import VideoWithPlayButton from "../../components/shared/youtube-scroll-bttn";
import videoPoster from "../../images/ida/moa/IDA_MOA_thumbnail.png"
import TranscriptAccordion from "../../components/shared/transcript-accordion"

import DosingIconOne from "../../components/hp/dosing-icon-1"
import DosingIconTwo from "../../components/hp/dosing-icon-2"
import DosingIconThree from "../../components/hp/dosing-icon-3"

import EopCalloutCards from "../../components/shared/generic/eop-callout-cards"
import akbcares from "../../images/AkebiaCares-logo.png"
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import CalloutIcon1 from "../../images/icons/icon-callout-hp-efficacy.png"
import CalloutIcon2 from "../../images/icons/icon-callout-hp-safety.png"

const anchorClick = event => {
  event.preventDefault();
  event.stopPropagation();

  const bounds = document.getElementById('trial-design').getBoundingClientRect();
  let scrollPosition = window.pageYOffset + bounds.top;

  // Adjust for the height of the sticky header 
  let stickyHdr;
  setTimeout(function () {
    if (window.matchMedia("(min-width: 768px)").matches) {
      stickyHdr = document.getElementById('site-navigation').getBoundingClientRect().height;
    }
    else {
      stickyHdr = document.getElementById('hpp-navigation').getBoundingClientRect().height;
    }
    scrollPosition = scrollPosition - stickyHdr;
    window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
  }, 100);
};

const hppMOA = ({ location }) => (
  <HPPLayout location={location}
  locationHostName={location.hostname}
    jobCode="PP-AUR-US-1407 (v7.0)"
    jobCodeDate="07/23"
    references={[
      <>AURYXIA<sup>®</sup> [Package Insert]. Cambridge, MA: Akebia Therapeutics, Inc.</>,
      <>Ganz T, Bino A, Salusky IB. Mechanism of action and clinical attributes of AURYXIA<sup>&#x00AE;</sup> (Ferric Citrate). <em>Drugs</em>. 2019;79(9):957-968. doi:10.1007/s40265-019-01125-w</>,
      <>Webster AC, Nagler EV, Morton RL, Masson P. Chronic kidney disease. <em>Lancet</em>. 2017;389(10075):1238-1252. doi:10.1016/S0140-6736(16)32064-5</>,
      <>Hruska KA, Mathew S, Lund R, Qiu P, Pratt R. Hyperphosphatemia of chronic kidney disease. <em>Kidney Int</em>. 2008;74(2):148-157. doi:10.1038/ki.2008.130</>,
      <>Pergola PE, Fishbane S, Ganz T. Novel oral iron therapies for iron deficiency anemia in chronic kidney disease. <em>Adv Chronic Kidney Dis</em>. 2019;26(4):272-291. doi:10.1053/j.ackd.2019.05.002</>,
      <>Yagil Y, Fadem SZ, Kant KS, et al. Managing hyperphosphatemia in patients with chronic kidney disease on dialysis with ferric citrate: latest evidence and clinical usefulness. <em>Ther Adv Chronic Dis.</em> 2015;6(5):252-263. doi:10.1177/2040622315589934</>,
      <>Pennoyer A, Bridgeman MB. Ferric citrate (AURYXIA) for the treatment of hyperphosphatemia. <em>P T</em>. 2015;40(5):329-339.</>,
      <>Iida A, Kemmochi Y, Kakimoto K, et al. Ferric citrate hydrate, a new phosphate binder, prevents the complications of secondary hyperparathyroidism and vascular calcification. <em>Am J Nephrol</em>. 2013;37(4):346-358. doi:10.1159/000348805</>,
      <>Data on File 1, Akebia Therapeutics, Inc.</>,
      <>Umanath K, Sika M, Niecestro R, et al; Collaborative Study Group. Rationale and study design of a three period, 58-week trial of ferric citrate as a phosphate binder in patients with ESRD on dialysis. <em>Hemodial Int.</em> 2013;17(1):67-74. doi:10.1111/j.1542-4758.2012.00711.x</>
    ]}>
    <Helmet>
      <html lang="en" />
      <link rel="canonical" href="https://www.auryxiahcp.com/hyperphosphatemia/mechanism-of-action" />
      <body id="hpp-hcp-moa" className="hpp" />
    </Helmet>

    <Seo title="Mechanism of Action" description="See how AURYXIA® (ferric citrate) tablets work in the body and learn about their unique formulation." />
    <section id="hero" className="col-10 center">
      <div className="flex-row">
        <div>
          <h1>Mechanism of action of AURYXIA</h1>
          <p>Watch this video and learn how the unique formulation of AURYXIA works in the body to help manage adult patients&#x2019; phosphorus&nbsp;levels.<sup>1,2</sup></p>

          <div className="video-wrap hpp">
            {/* <VideoWithPlayButton
              accountId="5982844371001"
              playerId="eQq6v3QYZR"
              videoId="6189938633001"
              posterImg={videoPoster} /> */}
            <VideoWithPlayButton
              // url = "https://www.youtube.com/embed/LVGUnE2m7xQ?controls=0&modestbranding=1&showsearch=0"
              url = "https://www.youtube.com/embed/LVGUnE2m7xQ"
              // width="100%" 
              // height="100%"
              posterImg={videoPoster} 
            />
          </div>

        </div>
      </div>
    </section>

    <section id="transcript">
      <TranscriptAccordion
        siteAudience="hpp"
        transcriptTitle="VIEW TRANSCRIPT"
        transcriptContent={<>
          <p>Chronic kidney disease, CKD, is characterized by a gradual loss of kidney function.<sup>3</sup> The kidneys play an important role in regulating the amount of phosphorus in blood, removing excess phosphorus for renal excretion.<sup>3,4</sup> Loss of kidney function in end stage renal disease diminishes the kidney&#x2019;s ability to control serum phosphate levels, causing hyperphosphatemia.<sup>4</sup></p>

          <p>AURYXIA is a unique formulation of ferric citrate coordination complexes indicated for the control of serum phosphorus levels in adults with CKD receiving dialysis.<sup>1,2,5</sup> AURYXIA is an iron-based, calcium-free, non-chewable phosphate binder, which after swallowing reaches the gastrointestinal tract where ferric iron binds to dietary phosphates to produce ferric phosphate, an insoluble compound that is readily excreted in fecal matter.<sup>1,2,6-8</sup> By lowering gastrointestinal phosphate absorption, AURYXIA reduces serum phosphorus concentrations in patients with CKD receiving dialysis.<sup>1,2,6-8</sup></p>
        </>} />
    </section>

    <section id="how-auryxia-works" className="col-10 center">
      <div>
        <div>
          <h2 className="hpp">How AURYXIA lowers phosphate levels</h2>
          <p>AURYXIA is a unique formulation of ferric citrate coordination complexes for control of serum phosphorus levels in adults with CKD receiving dialysis.<sup>1,2,5</sup><span></span></p>
          <div className="flex-row">

            <div>
              <DosingIconOne />
              <p>Ferric iron binds to dietary phosphate in the GI tract to produce ferric phosphate<sup>1</sup></p>
            </div>
            <div>
              <DosingIconTwo />
              <p>This compound is insoluble and is readily excreted in the stool<sup>1</sup></p></div>

            <div>
              <DosingIconThree />
              <p>By lowering gastrointestinal phosphate absorption, AURYXIA reduces serum phosphorus concentrations<sup>1</sup></p></div>
          </div>
        </div>
      <p className="footnote center margint-4">CKD=chronic kidney disease.</p>
      </div>
    </section>

    <EopCalloutCards
      siteAudience="hpp"
      callOneClass={"highlight"}
      callOneContent={
        <>
         <div>
          <img src={CalloutIcon1} alt="Artistic representation of two pages stacked on top of each other" width="98" height="98" />
          <h3>See how AURYXIA helped patients reach their target goals</h3>
          <p>AURYXIA helped patients reach and stay in the range of <span className="nowrap">3.5-5.5 mg/dL</span> during a 56-week trial.<sup>1</sup><br />
            Patients had a mean serum phosphorus level of 7.41 mg/dL at baseline and <span className="nowrap">4.88 mg/dL</span> at Week 56.<sup>9</sup></p>
         </div>
         <div>
          <a href="/hyperphosphatemia/ferric-citrate-efficacy/" className="cta hpp"><span>EXAMINE EFFICACY</span></a>
          <p><span className="gradient-link anchor-link hpp" onClick={anchorClick} onKeyDown={anchorClick} role="button" tabIndex={0}>See trial design <KeyboardArrowDown viewBox="6 6 12 12" /></span></p>
         </div>
        </>
      }
      callTwoClass={"default"}
      callTwoLink={"/hyperphosphatemia/ferric-citrate-reactions/"}
      callTwoContent={
        <>
         <div>
            <img src={CalloutIcon2} alt="Artistic representation of a pill bottle with a capsule logo" width="98" height="98" />
            <h3>Get to know the proven safety profile of AURYXIA</h3>
            <p>See the product safety and tolerability profile for AURYXIA in a <span className="nowrap">52-week</span> trial.<sup>1</sup></p>
         </div>
          <div>
            <button className="cta hpp"><span>EXPLORE SAFETY</span></button>
            <p>&nbsp;</p>
          </div>
        </>
      }
    />

    <section id="crosslink-slab" className="akb akb_moa_section">
      <div className="flex-row"
        data-sal="zoom-in"
        data-sal-duration="600"
        data-sal-delay="200"
        data-sal-ease="ease-out-expo">
        <div>
          <img src={akbcares} alt="Akebia Cares logo" height="81" />
          <h3>Your partner in helping patients access the medication they need.</h3>
          <p className="boldTxt">Connect with a personal Case Manager today!</p>
          <p className="med_width">AkebiaCares personal Case Managers are standing by live to answer your call and help your patients find coverage.
        </p>
          <ul className="info_ul">
            <li>1&#xFEFF;-&#xFEFF;833&#xFEFF;-&#xFEFF;4AKEBIA&nbsp;(425&#xFEFF;-&#xFEFF;3242)</li>
            <li>Monday&nbsp;-&nbsp;Friday</li>
            <li className="hours_li">8<span>AM</span>&nbsp;‐&nbsp;8<span>PM</span>&nbsp;ET</li>
          </ul>

          <a href="https://www.akebiacareshcp.com/" className="cta akb"><span>FIND SUPPORT OPTIONS</span></a>

        </div>
      </div>
    </section>

    <section id="interested-slab" className="col-6 center">
      <div className="flex-row"
        data-sal="slide-up"
        data-sal-duration="600"
        data-sal-delay="200"
        data-sal-ease="ease-out-expo">
        <div>
          <h3>You may also be interested&nbsp;in: </h3>
          <p><Link to="/hyperphosphatemia/patient-profiles/" className="gradient-link hpp">PATIENT PROFILES</Link></p>
        </div>

      </div>
    </section>

    <section id="trial-design" className="flood-hp col-8">
      <div className="flex-row">
        <div>
          <p><strong>Trial design<sup>1,10</sup></strong></p>
          <p>A multicenter, randomized, open‐label trial evaluated the ability of AURYXIA to lower serum phosphorus in patients with CKD on dialysis over 56 weeks. Eligible patients had serum ferritin &#x003C;1000 ng/mL, serum TSAT &#x003C;50%, and serum phosphorus ≥2.5 and ≤8.0 mg/dL at the screening visit. The safety and efficacy of AURYXIA were studied in the 52‐week active‐controlled period (AURYXIA n=292, Active Control n=149), then AURYXIA patients were re‐randomized to either continue AURYXIA treatment or receive placebo during the placebo‐controlled period, weeks 52‐56 (AURYXIA n=96, placebo n=96). The primary endpoint was the change in serum phosphorus from baseline (Week 52) to Week 56 between AURYXIA and placebo. The key secondary endpoint was the change in serum phosphorus from baseline (Week 0) to Week 52 between AURYXIA and Active Control.</p>

          {/* <p>A multicenter, randomized, open-label, Phase III trial evaluated the safety and efficacy of AURYXIA as a phosphate binder in controlling serum phosphorus levels in adult patients with CKD on hemodialysis and peritoneal dialysis over 56 weeks. Eligible patients were on dialysis for &#x2265;3 months before screening, were prescribed 3 to 18 pills/day of commercially available phosphate binder, and had serum ferritin &#x003C;1000 ng/mL, serum TSAT &#x003C;50%, and serum phosphorus &#x2265;2.5 and &#x2264;8.0 mg/dL at the screening visit. Patients who were intolerant to calcium acetate and sevelamer carbonate were not included in the trial.</p>
          <p>The safety and efficacy of AURYXIA was studied in the 52-week Active Control Period (AURYXIA n=292, Active Control n=149). At the final Active Control Period visit, AURYXIA patients were re-randomized to either continue AURYXIA treatment or receive placebo as part of the Placebo-Controlled Period (AURYXIA n=96, placebo n=96). The primary endpoint of the pivotal trial was the change in serum phosphorus from baseline (Week 52) to Week 56 between AURYXIA and placebo in the 4-week Placebo-Controlled Period.</p> */}

          <p className="footnote center">TSAT=transferrin saturation; Active Control=sevelamer carbonate and/or calcium acetate.</p>
        </div>
      </div>
    </section>
  </HPPLayout>
)

export default hppMOA
